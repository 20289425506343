.react-daterange-picker__wrapper {
  border: 0px solid #ccc !important;
}

.modal {
  display: block;
}

.shell {
  position: relative;
  line-height: 1;
}
.shell span {
  position: absolute;
  left: 3px;
  top: 1px;
  color: #ccc;
  pointer-events: none;
  z-index: -1;
}
.shell span i {
  font-style: normal;
  /* any of these 3 will work */
  color: transparent;
  opacity: 0;
  visibility: hidden;
}

input.masked,
.shell span {
  font-size: 16px;
  font-family: monospace;
  padding-right: 10px;
  background-color: transparent;
  text-transform: uppercase;
}

.print-voucher-modal {
  overflow-y: scroll;
}

:root {
  --general-font-color: rgb(53, 53, 155);
  --table-head-bg: rgb(215, 232, 255);
}

#main {
  width: 100%;
  min-height: 100vh;
  height: auto;
  background-color: rgb(234, 239, 253);
  /*------- GRID setup --------*/
  display: grid;
  grid-template-rows: 10% 90%;
  grid-template-columns: 20% 80%;
}

.tab-content {
  width: 95%;
  min-height: 60vh;
  height: auto;
  padding: 1em;
  margin: 2em auto auto auto;
  border-radius: 1em;
  background-color: white;
}

/*
.grid-item {
border: var(--general-border); 
}
*/

/* ------------ navbar ---------- */

#top-navbar {
  grid-column: 1/3;
  grid-row: 1/2;
  background-color: white;
  /*--- flex ----- */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* -------- aside section ---------------- */

aside {
  grid-row: 2/3;
  grid-column: 1/2;
  background-color: var(--general-font-color);
}

aside .user-info {
  width: 100%;
  height: 6em;
  border-bottom: 1px solid rgb(56, 56, 56);
  margin-bottom: 2em;
  padding: 1em;
  color: white;
}

.user-info p {
  color: rgb(39, 177, 231);
}

aside ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

aside li a {
  text-decoration: none;
  color: white;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

aside i {
  margin-right: 0.5em;
}

aside a:hover {
  color: white;
}

.color-white {
  color: white !important;
}


aside a:focus {
  color: var(--general-font-color);
  background-color: white;
}

aside a:active {
  color: var(--general-font-color);
  background-color: white;
}

/* --------------------- main section ------------------- */

#recurrent-payments .head {
  width: 100%;
  height: 5vh;
  /* ---- flex ----*/
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* ------ head-section on recurring payment tab --------------*/

.head h5 {
  color: var(--general-font-color);
  text-decoration: none;
}

.head .btn {
  color: var(--general-font-color);
  background-color: white;
}

/* -------- cms modal ----------------*/

#cms-modal {
  width: 40%;
  height: 30vh;
  margin: 30vh 25%;
  background-color: white;
}

#cms-modal h5 {
  color: var(--general-font-color);
}

#cms-modal .form-row {
  color: rgb(129, 129, 129);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#file-btn {
  background-color: white;
  color: white;
}

#modal-btn-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

#modal-btn-box .btn {
  margin: auto 0.5em;
  color: var(--general-font-color);
}

#modal-btn-box .btn:focus {
  color: white;
  background-color: var(--general-font-color);
}

/* ---------- option-box & filter box -------------- */

.option-box {
  width: 100%;
  height: 15vh;
  margin-bottom: 3em;
}

.option-box h6,
a {
  color: var(--general-font-color);
  text-decoration: none;
}

.option-box .btn {
  /* width: 10%; */
  color: white;
  background-color: var(--general-font-color);
}

.option-box label {
  color: gray;
}

.filter-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.table {
  margin-top: 1em;
}

.table thead {
  background-color: var(--table-head-bg);
}

.table thead td {
  color: var(--general-font-color);
  font-weight: bold;
}

.nav-page {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
}

.page-link:active,
.page-link:focus {
  background-color: var(--general-font-color);
  color: white;
}

.react-date-picker__wrapper {
  border: 0 solid white !important;
}

::-webkit-file-upload-button {
  border-width: 0;
  background-color: transparent;
  color: var(--general-font-color);
}

input[type="file"] {
  padding-left: 5px;
}

.hidden {
  display: none;
}

.text-align-right {
  text-align: right;
}

.scrollable-y {
  overflow-y: auto;
}
